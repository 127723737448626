import React, { useState } from "react"
import { Navbar, Nav } from "react-bootstrap"
import { Link } from "gatsby"
import { SearchBtn } from "../Button"
import { SearchForm } from "../Search"
import { Container, Row, Col } from "react-bootstrap"
import "./style.scss"

export default props => {
  const [isSearching, updateDisplay] = useState(false)

  return (
    <>
      {!isSearching && (
        <Navbar {...props}>
          <Nav.Item>
            <Link
              className={"nav-link"}
              to={"/first-responders/about"}
              activeClassName={"active"}
              partiallyActive={true}
            >
              {"About"}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link
              className={"nav-link"}
              to={"/first-responders/toolbox"}
              activeClassName={"active"}
              partiallyActive={true}
            >
              {"Toolbox"}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link
              className={"nav-link"}
              to={"/first-responders/community"}
              activeClassName={"active"}
              partiallyActive={true}
            >
              {"Our Community"}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link
              className={"nav-link"}
              to={"/first-responders/news"}
              activeClassName={"active"}
              partiallyActive={true}
            >
              {"In The News"}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link
              className={"nav-link"}
              to={"/first-responders/contact"}
              activeClassName={"active"}
            >
              {"Contact"}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <SearchBtn onClick={() => updateDisplay(true)} />
          </Nav.Item>
        </Navbar>
      )}
      {isSearching && (
        <Container id={"site-navbar"} className={"search-wrap fixed-top"} fluid>
          <Container className={"text-right"}>
            <Row className={"d-flex align-items-center"}>
              <Col></Col>
              <Col md={8} className={"d-flex flex-row align-items-center"}>
                <SearchForm searchIconOnly={true} />
              </Col>
              <Col>
                <a
                  href="#"
                  className={"close"}
                  onClick={() => updateDisplay(false)}
                >
                  {"CLOSE"}
                </a>
              </Col>
            </Row>
          </Container>
        </Container>
      )}
    </>
  )
}
