import React, { useState } from "react"
import { Navbar, Nav } from "react-bootstrap"
import { Link } from "gatsby"
import { SearchBtn } from "../Button"
import { SearchForm } from "../Search"
import { Container, Row, Col } from "react-bootstrap"
import "./style.scss"

export default props => (
  <Navbar {...props}>
    <Nav.Item>
      <Link
        className={"nav-link"}
        to={"/our-mission"}
        activeClassName={"active"}
        partiallyActive={true}
      >
        {"Our Mission"}
      </Link>
    </Nav.Item>
    <Nav.Item>
      <Link
        className={"nav-link"}
        to={"/resources"}
        activeClassName={"active"}
        partiallyActive={true}
      >
        {"Resources"}
      </Link>
    </Nav.Item>
    <Nav.Item>
      <Link
        className={"nav-link"}
        to={"/in-the-news"}
        activeClassName={"active"}
        partiallyActive={true}
      >
        {"In The News"}
      </Link>
    </Nav.Item>
  </Navbar>
)
