import React from "react"
import { ButtonGroup } from "react-bootstrap"
import { Link } from "gatsby"

export default ({ className, darkMode }) => (
  <ButtonGroup size="sm" className={className ? `${className} tab` : `tab`}>
    <Link
      className={"btn btn-secondary"}
      variant="secondary"
      to={"/"}
      activeClassName={"active"}
      partiallyActive={darkMode ? false : true}
    >
      {"Public"}
    </Link>
    <Link
      className={"btn btn-secondary"}
      variant="secondary"
      to={`/first-responders`}
      activeClassName={"active"}
      partiallyActive={true}
    >
      {"First Responders"}
    </Link>
  </ButtonGroup>
)
