import React, { useEffect } from "react"
import Helmet from "react-helmet"
import Header from "../Header"
import NavBar from "../NavBar"
import NavBarFP from "../NavBar/FP"
import Content from "../Content"
import Footer from "../Footer"
import "../../styles/global.scss"
import { Location } from "@reach/router"
import "intersection-observer"
import "custom-event-polyfill"

export default ({
  pageInfo: { pageName: id },
  children,
  fluid = false,
  hideFooter = false,
  language,
}) => {
  const getPath = () => `${id}`.toLowerCase().replace(/ /g, "-")

  const navbarTransition = () => {
    let siteNavbar = document.getElementById("site-navbar")
    if (window.pageYOffset > 20) {
      siteNavbar.classList.add("pageScrolled")
    } else {
      siteNavbar.classList.remove("pageScrolled")
    }
  }

  useEffect(() => {
    if ("first-responders-homepage" === getPath()) {
      window.addEventListener("scroll", navbarTransition, false)
    } else {
      window.removeEventListener("scroll", navbarTransition, false)
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  // Add TrustArc cookies consent notice to the bottom of the page
  useEffect(() => {
    const script = document.createElement("script")

    script.src =
      "//consent.trustarc.com/notice?domain=ulresearch.com&c=teconsent&js=nj&noticeType=bb&text=true&gtm=1"
    script.async = true

    document.body.appendChild(script)
  }, [])

  return (
    <Location>
      {({ location: { href = "" } }) => {
        let currentPath = getPath()
        let isDarkMode = -1 !== href.indexOf("first-responders")
        let classes = isDarkMode ? `${currentPath} dark-mode` : currentPath
        return (
          <>
            <Helmet
              htmlAttributes={{
                class: `page-${classes}`,
              }}
              bodyAttributes={{
                class: `page-${classes}`,
              }}
            >
              {/* Global site tag (gtag.js) - Google Analytics */}
              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=UA-6192474-30"
              ></script>
              <script>
                {`window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'UA-6192474-30');`}
              </script>
            </Helmet>
            {!isDarkMode && (
              <>
                <Header
                  fixed={"top"}
                  currentPath={currentPath}
                  language={language}
                />
                {language !== "es" && (
                  <NavBar
                    id={"site-navbar"}
                    fixed={"top"}
                    variant={"dark"}
                    className={"d-none d-md-flex"}
                  />
                )}
              </>
            )}
            {isDarkMode && (
              <>
                <Header
                  darkMode={true}
                  fixed={"top"}
                  currentPath={currentPath}
                />
                <NavBarFP
                  id={"site-navbar"}
                  fixed={"top"}
                  variant={"dark"}
                  className={"d-none d-md-flex"}
                />
              </>
            )}
            <Content fluid={fluid}>{children}</Content>
            <div
              id="consent_blackbar"
              style={{ position: "absolute", bottom: 0, width: "100%" }}
            ></div>
            {!hideFooter && <Footer />}
          </>
        )
      }}
    </Location>
  )
}
