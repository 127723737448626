import React from "react"
import { library, config } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Col, Row, Container } from "react-bootstrap"
import { Link } from "gatsby"
import { faArrowUp, faSearchPlus } from "@fortawesome/free-solid-svg-icons"
import {
  faArrowRight,
  faArrowLeft,
  faArrowDown,
  faDoorClosed,
  faTimes,
  faPlay,
  faSearch,
  faShippingFast,
} from "@fortawesome/free-solid-svg-icons"
import "@fortawesome/fontawesome-svg-core/styles.css"
import "./style.scss"

config.autoAddCss = false

library.add(
  faPlay,
  faArrowRight,
  faArrowLeft,
  faArrowUp,
  faArrowDown,
  faDoorClosed,
  faTimes,
  faShippingFast,
  faSearch,
  faSearchPlus
)

export const IconButton = props => {
  return (
    <Button
      variant={"transparent-dark"}
      className={"bsbtn"}
      onClick={props.onClick ? props.onClick : {}}
    >
      <FontAwesomeIcon {...props} />
    </Button>
  )
}

export const LinkButton = ({ className, label, link }) => (
  <Button
    href={link}
    target={"_blank"}
    rel={"noopener norefferer"}
    variant={"external-link"}
    className={className ? `bsbtn ${className}` : `bsbtn`}
  >
    <Container className="ml-2 py-2" fluid>
      <Row>
        <Col xs={10} className={"text-left label"}>
          {`External Link`}
        </Col>
      </Row>
      <Row>
        <Col xs={10} className={"pr-0"}>
          <p className="ext-name mb-0">{label}</p>
          <p className="ext-link mb-1">{link}</p>
        </Col>
        <Col xs={2} className={"text-right pr-2 pl-0"}>
          <FontAwesomeIcon icon={faArrowUp} />
        </Col>
      </Row>
    </Container>
  </Button>
)

export const SearchBtn = props => <IconButton icon={faSearch} {...props} />

export const LoadMore = ({ className, onClick }) => (
  <Button
    variant={"grey-outline"}
    onClick={() => onClick()}
    className={className ? `py-3 bsbtn w-100 ${className}` : `bsbtn w-100 py-3`}
  >
    {"Load More"}
  </Button>
)

export default props => {
  let className = props.className ? `bsbtn btn ${props.className}` : "btn bsbtn"
  let icon = "preview" === props.variant ? faSearchPlus : props.icon

  if (props.to) {
    return (
      <Link {...props} className={className}>
        {props.icon ? <FontAwesomeIcon icon={icon} /> : null}
        {props.label}
      </Link>
    )
  }

  return (
    <Button {...props} className={className}>
      {props.icon ? <FontAwesomeIcon icon={icon} /> : null}
      {props.label}
    </Button>
  )
}
