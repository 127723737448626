import React, { useState } from "react"
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap"
import "./style.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faXTwitter,
  faFacebookF,
  faInstagram,
  faYoutube,
  faVimeo,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"
import Logo from "../../assets/brand/CBYD_logo.svg"
import TabGroup from "./../TabGroup"
import TabGroupLang from "./../TabGroupLang"
import { Link } from "gatsby"
import { SearchForm } from "../Search"

const PublicSocial = () => (
  <Navbar className="justify-content-end no-padding">
    <Nav.Link
      href="https://www.facebook.com/FSRI.org/"
      className="social-icon"
      target={"_blank"}
    >
      <FontAwesomeIcon color="#6CB2E1" icon={faFacebookF} />
    </Nav.Link>
    <Nav.Link
      href="https://twitter.com/FSRI_org"
      className="social-icon"
      target={"_blank"}
    >
      <FontAwesomeIcon color="#6CB2E1" icon={faXTwitter} />
    </Nav.Link>
    <Nav.Link
      href="https://vimeo.com/fsri"
      className="social-icon"
      target={"_blank"}
    >
      <FontAwesomeIcon color="#6CB2E1" icon={faVimeo} />
    </Nav.Link>
    <Nav.Link
      href="https://www.youtube.com/firesafetyresearchinstitute"
      className="social-icon"
      target={"_blank"}
    >
      <FontAwesomeIcon color="#6CB2E1" icon={faYoutube} />
    </Nav.Link>
    <Nav.Link
      href="https://www.instagram.com/ulfsri/"
      className="social-icon"
      target={"_blank"}
    >
      <FontAwesomeIcon color="#6CB2E1" icon={faInstagram} />
    </Nav.Link>
    <Nav.Link
      href="https://www.linkedin.com/showcase/fsri-org"
      className="social-icon"
      target={"_blank"}
    >
      <FontAwesomeIcon color="#6CB2E1" icon={faLinkedin} />
    </Nav.Link>
  </Navbar>
)

const FPSocial = () => (
  <Navbar className="justify-content-end no-padding">
    <Nav.Link
      href="https://www.facebook.com/FSRI.org/"
      className="social-icon"
      target={"_blank"}
    >
      <FontAwesomeIcon color="#6CB2E1" icon={faFacebookF} />
    </Nav.Link>
    <Nav.Link
      href="https://twitter.com/FSRI_org"
      className="social-icon"
      target={"_blank"}
    >
      <FontAwesomeIcon color="#6CB2E1" icon={faXTwitter} />
    </Nav.Link>
    <Nav.Link
      href="https://vimeo.com/fsri"
      className="social-icon"
      target={"_blank"}
    >
      <FontAwesomeIcon color="#6CB2E1" icon={faVimeo} />
    </Nav.Link>
    <Nav.Link
      href="https://www.youtube.com/user/firesafetyresearchinstitute"
      className="social-icon"
      target={"_blank"}
    >
      <FontAwesomeIcon color="#6CB2E1" icon={faYoutube} />
    </Nav.Link>
    <Nav.Link
      href="https://www.instagram.com/ulfsri/"
      className="social-icon"
      target={"_blank"}
    >
      <FontAwesomeIcon color="#6CB2E1" icon={faInstagram} />
    </Nav.Link>
    <Nav.Link
      href="https://www.linkedin.com/showcase/fsri-org"
      className="social-icon"
      target={"_blank"}
    >
      <FontAwesomeIcon color="#6CB2E1" icon={faLinkedin} />
    </Nav.Link>
  </Navbar>
)

const PublicMobileNav = () => (
  <Nav>
    <Link className={"nav-link"} activeClassName={"active"} to={"/our-mission"}>
      {"Our Mission"}
    </Link>
    <Link className={"nav-link"} activeClassName={"active"} to={"/resources"}>
      {"Resources"}
    </Link>
    <Link className={"nav-link"} activeClassName={"active"} to={"/in-the-news"}>
      {"In The News"}
    </Link>
  </Nav>
)
const FPMobileNav = () => (
  <Nav>
    <Link className={"nav-link"} to={"/first-responders/about"}>
      {"About"}
    </Link>
    <Link className={"nav-link"} to={"/first-responders/toolbox"}>
      {"Toolbox"}
    </Link>
    <Link className={"nav-link"} to={"/first-responders/community"}>
      {"Our Community"}
    </Link>
    <Link className={"nav-link"} to={"/first-responders/news"}>
      {"In The News"}
    </Link>
    <Link className={"nav-link"} to={"/first-responders/contact"}>
      {"Contact"}
    </Link>
  </Nav>
)

export default ({ fixed = false, darkMode = false, currentPath, language }) => {
  const [collapsed, setCollapsed] = useState(false)
  let isPublicPage = currentPath === "home"
  return (
    <Navbar
      id="header"
      as="header"
      expand="md"
      className={"w-100 mt-0 mb-0 py-2"}
      fixed={fixed}
    >
      <Container className={"justify-content-center"}>
        {/* Desktop/Tablet Menu */}
        <Row className={"d-none d-md-flex w-100 align-items-center"}>
          <Col className={"pl-0"}>
            {collapsed ? "" : <TabGroup darkMode={darkMode} />}
          </Col>
          {isPublicPage && (
            <Col className={"pl-0"}>
              {collapsed ? "" : <TabGroupLang darkMode={darkMode} />}
            </Col>
          )}
          <Col className={"text-center"}>
            <Link
              className={"navbar-brand"}
              to={darkMode ? "/first-responders" : "/"}
            >
              <Logo />
            </Link>
          </Col>
          <Col className={"pr-0"}>
            {!darkMode && <PublicSocial />}
            {darkMode && <FPSocial />}
          </Col>
        </Row>

        {/* Mobile Menu */}
        <Row className={"d-md-none mobile w-100 align-items-center"}>
          <Col className={""} xs={2}>
            <Link className={"navbar-brand"} to={"/"}>
              <Logo />
            </Link>
          </Col>
          <Col className="text-right mobile-tab-group-padding" xs={10}>
            {collapsed && <TabGroup className={"mobile-button-tab-margin"} />}
            {isPublicPage && (
              <>
                {collapsed && (
                  <TabGroupLang className={"mobile-button-tab-margin"} />
                )}
              </>
            )}
            <Navbar.Toggle
              className={"hamburger"}
              onClick={() => setCollapsed(!collapsed)}
            >
              {collapsed ? (
                <FontAwesomeIcon icon={faTimes} />
              ) : (
                <FontAwesomeIcon icon={faBars} />
              )}
            </Navbar.Toggle>
          </Col>
        </Row>
      </Container>

      {/* Mobile Dropdown Menu */}
      <Container fluid className={"d-md-none mobile-dropdown"}>
        <Row className={"w-100 mx-0"}>
          <Col className={"px-0"}>
            <TabGroup darkMode={darkMode} className={"d-none pt-3 pb-1 pl-3"} />
            {isPublicPage && (
              <TabGroupLang
                darkMode={darkMode}
                className={"d-none pt-3 pb-1 pl-3"}
              />
            )}
            <Navbar.Collapse>
              {darkMode && (
                <Container>
                  <SearchForm
                    searchIconOnly={true}
                    onSubmit={() => setCollapsed(false)}
                  />
                </Container>
              )}
              {language !== "es" && (
                <Container className="links pb-3">
                  {!darkMode && <PublicMobileNav />}
                  {darkMode && <FPMobileNav />}
                </Container>
              )}
              <Navbar className="social">
                {!darkMode && (
                  <>
                    <Nav.Link
                      href="https://www.facebook.com/FSRI.org/"
                      className="social-icon"
                      target={"_blank"}
                      rel={"noopener norefferer"}
                    >
                      <FontAwesomeIcon color="#6CB2E1" icon={faFacebookF} />
                    </Nav.Link>
                    <Nav.Link
                      href="https://twitter.com/FSRI_org"
                      className="social-icon"
                      target={"_blank"}
                      rel={"noopener norefferer"}
                    >
                      <FontAwesomeIcon color="#6CB2E1" icon={faXTwitter} />
                    </Nav.Link>
                    <Nav.Link
                      href="https://www.instagram.com/ulfsri/"
                      className="social-icon"
                      target={"_blank"}
                      rel={"noopener norefferer"}
                    >
                      <FontAwesomeIcon color="#6CB2E1" icon={faInstagram} />
                    </Nav.Link>
                  </>
                )}
                {darkMode && (
                  <>
                    <Nav.Link
                      href="https://www.facebook.com/FSRI.org/"
                      className="social-icon"
                      target={"_blank"}
                      rel={"noopener norefferer"}
                    >
                      <FontAwesomeIcon color="#6CB2E1" icon={faFacebookF} />
                    </Nav.Link>
                    <Nav.Link
                      href="https://twitter.com/FSRI_org"
                      className="social-icon"
                      target={"_blank"}
                      rel={"noopener norefferer"}
                    >
                      <FontAwesomeIcon color="#6CB2E1" icon={faXTwitter} />
                    </Nav.Link>
                    <Nav.Link
                      href="https://vimeo.com/fsri"
                      className="social-icon"
                      target={"_blank"}
                      rel={"noopener norefferer"}
                    >
                      <FontAwesomeIcon color="#6CB2E1" icon={faVimeo} />
                    </Nav.Link>
                    <Nav.Link
                      href="https://www.youtube.com/user/firesafetyresearchinstitute"
                      className="social-icon"
                      target={"_blank"}
                      rel={"noopener norefferer"}
                    >
                      <FontAwesomeIcon color="#6CB2E1" icon={faYoutube} />
                    </Nav.Link>
                    <Nav.Link
                      href="https://www.instagram.com/ulfsri/"
                      className="social-icon"
                      target={"_blank"}
                      rel={"noopener norefferer"}
                    >
                      <FontAwesomeIcon color="#6CB2E1" icon={faInstagram} />
                    </Nav.Link>
                    <Nav.Link
                      href="https://www.linkedin.com/showcase/fsri-org"
                      className="social-icon"
                      target={"_blank"}
                    >
                      <FontAwesomeIcon color="#6CB2E1" icon={faLinkedin} />
                    </Nav.Link>
                  </>
                )}
              </Navbar>
              <Container className="footer" fluid>
                <Container className="py-3">
                  <Nav>
                    <Nav.Link
                      href="http://www.ul.com/customer-resources/online-policies/privacy-policy/"
                      target={"_blank"}
                      rel={"noopener norefferer"}
                    >
                      {"Privacy Policy"}
                    </Nav.Link>
                    <Nav.Link
                      href="http://www.ul.com/customer-resources/online-policies/terms-of-use/"
                      target={"_blank"}
                      rel={"noopener norefferer"}
                    >
                      {"Terms of Use"}
                    </Nav.Link>
                  </Nav>
                </Container>
              </Container>
            </Navbar.Collapse>
          </Col>
        </Row>
      </Container>
    </Navbar>
  )
}
