import React, { useState, useEffect, useRef } from "react"
import { Col, Row, Container, Form } from "react-bootstrap"
import Button, { SearchBtn } from "../Button"
import { navigate } from "gatsby"
import "./style.scss"

export const SearchForm = props => {
  const fpEl = useRef(null)

  let { defaultValue, onChange, onSubmit, searchIconOnly } = props

  if (fpEl.current && defaultValue) {
    fpEl.current.value = defaultValue
  }

  const submit = () => {
    let q = encodeURIComponent(fpEl.current.value)
    navigate(`/first-responders/search-results/?q=${q}`)
    onChange && onChange(q)
    onSubmit && onSubmit(q)
    return false
  }

  const handleSubmit = event => {
    const form = event.currentTarget

    event.preventDefault()

    if (form.checkValidity() === false) {
      return false
    }

    return submit()
  }

  return (
    <Container fluid={true}>
      <Form
        className={"w-100 d-flex flex-row align-items-center"}
        noValidate
        onSubmit={handleSubmit}
      >
        <Form.Control
          ref={fpEl}
          size="sm"
          type="text"
          defaultValue={defaultValue}
          placeholder="Search"
          className={"foo mr-4"}
          autoFocus
        />
        {!searchIconOnly && (
          <Button
            onClick={() => submit()}
            variant={"bg-red"}
            className={"search-btn"}
            icon={"search"}
            label={"Search"}
          />
        )}
        {searchIconOnly && (
          <SearchBtn className={"d-inline"} onClick={() => submit()} />
        )}
      </Form>
    </Container>
  )
}

export default ({ query, cache }) => {
  const [searchQuery, setSearchTerm] = useState()
  const [results, setResults] = useState([])
  const [news, updateNews] = useState([])
  const [resources, updateResources] = useState([])

  if (query !== searchQuery) {
    setSearchTerm(query)
  }

  useEffect(() => {
    let _news = {},
      _resources = {}

    cache.news.map(({ id }) => {
      _news[id] = id
      return null
    })
    updateNews(_news)

    cache.resources.map(({ id }) => {
      _resources[id] = id
      return null
    })
    updateResources(_resources)
  }, [query, cache.news, cache.resources])

  useEffect(() => {
    if (window.__LUNR__ && query) {
      const lunrIndex = window.__LUNR__["en"]
      const searchResults = lunrIndex.index.search(query)

      setResults(searchResults.map(({ ref }) => lunrIndex.store[ref]))
    } else {
      setResults([])
    }
  }, [query])

  return (
    <Container>
      <div className={"mb-5"}>
        <SearchForm
          defaultValue={searchQuery}
          onChange={value => setSearchTerm(value)}
        />
      </div>

      <ul className={"search-results"}>
        {!results.length && <li className={"no-results"}>No results found.</li>}

        {results.length > 1 && (
          <li className={"no-results"}>{`${results.length} results found.`}</li>
        )}

        {results.map(props => {
          let { id, slug, title, type, caption, description, pdf } = props

          if ("NodeNewsResource" === type) {
            return (
              <li key={id}>
                <a
                  href={news[id].link?.uri}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Row>
                    <Col>
                      <div className={"title"}>{title}</div>
                      <div className={"link"}>{news[id].link?.uri}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className={"excerpt"}>{description}</div>
                    </Col>
                    <Col className={"text-right"}>
                      <div className={"preview"}>
                        <img
                          alt={"Search search caption"}
                          className={"rounded"}
                          src={news[id]}
                        />
                      </div>
                    </Col>
                  </Row>
                </a>
              </li>
            )
          }

          if ("NodePublicResource" === type) {
            return (
              <li key={id}>
                <a
                  href={resources[id].link?.uri}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Row>
                    <Col>
                      <div className={"title"}>{title}</div>
                      <div className={"link"}>{resources[id].link?.uri}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className={"excerpt"}>{caption}</div>
                    </Col>
                    <Col className={"text-right"}>
                      <div className={"preview"}>
                        {resources[id] && (
                          <img
                            alt={"Search search caption"}
                            className={"rounded"}
                            src={resources[id]}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </a>
              </li>
            )
          }

          return null
        })}
      </ul>
    </Container>
  )
}
