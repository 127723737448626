import React from "react"
import { ButtonGroup, Button } from "react-bootstrap"
import { Link } from "gatsby"

export default ({ className, darkMode }) => (
    <ButtonGroup size="sm" className={className ? `${className} tab` : `tab`}>
        <Link
            className={"btn btn-secondary"}
            variant="secondary"
            to={"/"}
            activeClassName={"active"}
        >
            {"Eng"}
        </Link>
        <Link
            className={"btn btn-secondary"}
            variant="secondary"
            to={`/es/`}
            activeClassName={"active"}
        >
            {"Es"}
        </Link>
    </ButtonGroup>
)
