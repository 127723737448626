import PropTypes from "prop-types"
import React from "react"
import "./style.scss"
import { Container, Row, Col } from "react-bootstrap"

const Content = ({ fluid, children }) => (
  <Container as="main" id="main-content" fluid={fluid ? fluid : false}>
    <Row noGutters>
      <Col>{children}</Col>
    </Row>
  </Container>
)

Content.propTypes = {
  children: PropTypes.any,
}

Content.defaultProps = {
  children: null,
}

export default Content
